import React, { Component } from 'react';
import { Link } from "gatsby"

// Import Images
import Logo from '../images/rooam-icon.svg'

// Import Components
import classnames from "classnames";

// Import Custom Page CSS
import "../styles/header.css"

class Header extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: typeof window !== `undefined` ? window.pageYOffset : 0,
      visible: true
    };
  }
  // Adds an event listener when the component is mount.
  componentDidMount() {
      if (typeof window !== `undefined`) {
          window.addEventListener("scroll", this.handleScroll);
      }
  }
  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
      if (typeof window !== `undefined`) {
          window.removeEventListener("scroll", this.handleScroll);
      }
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = typeof window !== `undefined` ? window.pageYOffset : 0;
    const visible = currentScrollPos < 100 || prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };

  state = {
    isProductDDActive: false,
    isResourcesDDActive: false,
    isCompanyDDActive: false, 
    isMobileMenuActive: false,
  }
  
  render() {

    const isProductActive = this.state.isProductDDActive;
    const isResourcesActive = this.state.isResourcesDDActive;
    const isCompanyActive = this.state.isCompanyDDActive;
    const isMenuActive = this.state.isMobileMenuActive;

    return (
      <div className={classnames("header", { "navbar--hidden": !this.state.visible })}>
        <div className={ isMenuActive ? "fixed-mobile-head active" : "fixed-mobile-head"}>
          <Link to="/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
            <span className="logo-box">
              <span className="logo">
                <img src={Logo} alt="Rooam Logo" />
              </span>
            </span>
          </Link>
          <span
            className={ isMenuActive ? "toast-box no-select active" : "toast-box no-select"}
            onClick={ () => {
                this.setState({isMobileMenuActive: !isMenuActive});
                document.body.classList.toggle('no-scroll');
                }
            }
            onKeyDown={this.isMenuActive}
            role="button" 
            tabIndex={0}
          >
            <span className="toast">
              <span className="line"></span>
              <span className="line bottom"></span>
            </span>
          </span>
        </div>
        <div className={ isMenuActive ? "head-link-wrap active" : "head-link-wrap"}>
          <div className="mobile-padding">
            <ul className="head-menu right float-right">
              <li className="outline-link">
                <a href="https://my.rooam.co/" target="_blank" rel="noopener noreferrer">
                  Log In
                </a>
              </li>
              <li className="nav-btn">
                <Link to="/request-demo/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                  Request Demo
                </Link>
              </li>
            </ul>
            <ul className="head-menu left">
              <li className="link-group">
                <div 
                  className={ isProductActive ? "nav-btn active" : "nav-btn"}
                  onMouseEnter={ () => this.setState({isProductDDActive: true}) }
                  onMouseLeave={ () => this.setState({isProductDDActive: false}) }
                  role="link" 
                  tabIndex={0}
                >
                  Products
                </div>
                <ul 
                  className={ isProductActive ? "dropdown active" : "dropdown"}
                  onMouseEnter={ () => this.setState({isProductDDActive: true}) }
                  onMouseLeave={ () => this.setState({isProductDDActive: false}) }
                >
                  <li>
                    <Link to="/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Overview
                    </Link>
                  </li>
                  <li>
                    <Link to="/tab/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Rooam Tab
                    </Link>
                  </li>
                  <li>
                    <Link to="/pay/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Rooam Pay
                    </Link>
                  </li>
                  <li>
                    <Link to="/order/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Rooam Order
                    </Link>
                  </li>
                  <li>
                    <Link to="/text-to-pay/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Text to Pay
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="link-group">
                <div 
                  className={ isResourcesActive ? "nav-btn active" : "nav-btn"}
                  onMouseEnter={ () => this.setState({isResourcesDDActive: true}) }
                  onMouseLeave={ () => this.setState({isResourcesDDActive: false}) }
                  role="link" 
                  tabIndex={0}
                >
                  Resources
                </div>
                <ul 
                  className={ isResourcesActive ? "dropdown active" : "dropdown"}
                  onMouseEnter={ () => this.setState({isResourcesDDActive: true}) }
                  onMouseLeave={ () => this.setState({isResourcesDDActive: false}) }
                >
                  <li>
                    <a href="mailto:help@rooam.co" target="_blank" rel="noopener noreferrer">
                      Contact Help
                    </a>
                  </li>
                  <li>
                    <Link to="/location-support/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Location Support
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      FAQ's
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="link-group">
                <div 
                  className={ isCompanyActive ? "nav-btn active" : "nav-btn"}
                  onMouseEnter={ () => this.setState({isCompanyDDActive: true}) }
                  onMouseLeave={ () => this.setState({isCompanyDDActive: false}) }
                  role="link" 
                  tabIndex={0}
                >
                  Company
                </div>
                <ul 
                  className={ isCompanyActive ? "dropdown active" : "dropdown"}
                  onMouseEnter={ () => this.setState({isCompanyDDActive: true}) }
                  onMouseLeave={ () => this.setState({isCompanyDDActive: false}) }
                >
                  <li>
                    <Link to="/about/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers/" onClick={ () => { document.body.classList.removeClass('no-scroll'); } }>
                      Careers
                    </Link>
                  </li>
                  <li>
                    <a href="mailto:hello@rooam.co" target="_blank" rel="noopener noreferrer">
                      Contact
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Header
